import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';

const EditBmMorningTask = () => {
	const { role } = useAuth();
	//const [clusterlist, setClusterlist] = useState([]);
	//const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	// Handle modal close
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const [mtDetails, setMtDetails] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const { mid } = useParams(); // Extract the mid from the URL parameters
	const [midSelected, setMidSelected] = useState(mid); // Set the midSelected state with the extracted mid
	//const [items, setItems] = useState([]);
	const [showSnackbar, setShowSnackbar] = useState(false);
	useEffect(() => {
		if (sessionStorage.getItem('showSnackbar') === 'true') {
			setShowSnackbar(true);
			sessionStorage.removeItem('showSnackbar');
			// Hide snackbar after 3 seconds
			setTimeout(() => setShowSnackbar(false), 2000);
		}
	}, []);

	useEffect(() => {
		if (mid) {

			setMidSelected(mid); // Set the midSelected state
		} else {
			console.error('mid is undefined or invalid');

		}
	}, [mid]);


	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};



	const [formData, setFormData] = useState({
		mid: '',
		cluster: '',
		cluster_id: '',
		mt0100: 'is All Manpower Available?',
		mt0101: '',
		mt0102: '',
		mt0200: 'is All Manpower in proper dress code?',
		mt0201: '',
		mt0202: '',
		mt0300: 'is Pooja Done',
		mt0301: '',
		mt0302: '',
		mt0400: 'Are all Biomedical equiment working',
		mt0401: '',
		mt0402: '',
		mt0500: 'is Doc Avaialble or Oncall Doc arranged?',
		mt0501: '',
		mt0502: '',
		mt0600: 'Previous day pending reports',
		mt0601: '',
		mt0602: '',
		mt0700: 'Are there Maintainance Observation',
		mt0701: '',
		mt0702: '',
		branch: '',
		emp_code: userEmp_code,
		createdDTM: '',
	});

	const today = new Date();
	const nowDay = new Date();
	nowDay.setDate(nowDay.getDate() - 1);
	// const previousDay = nowDay.toISOString().split('T')[0];
	// const todayDate = today.toISOString().split('T')[0];
	const yesterday = new Date(today);
	let yesterdayDate = null;
	if (today.getDay() === 1) { // Check if today is Monday   
		yesterday.setDate(today.getDate() - 2); // Set to Saturday
	} else {
		yesterday.setDate(today.getDate() - 1);
	}
	yesterdayDate = yesterday.toISOString().split('T')[0];




	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const fileSize = file.size / 1024 / 1024; // Convert size to MB

			if (!['image/jpeg', 'application/pdf'].includes(fileType)) {
				alert('Only .jpg or .pdf files are allowed.');
				e.target.value = ''; // Clear the input
				return;
			}

			if (fileSize > 5) {
				alert('File size must be less than 5MB.');
				e.target.value = ''; // Clear the input
				return;
			}

			// If file is valid, set it to state or perform further actions
			// setFile(file); // Example state update
		}
	};

	const handleFileSubmit = async (e) => {
		e.preventDefault();
		const fileInput = e.target.elements.file;
		const file = fileInput.files[0];

		if (!file) {
			alert('Please select a file to upload.');
			return;
		}

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('mid', midSelected);

			const response = await fetch(`${process.env.REACT_APP_API_URL}api/uploadFile`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status}`);
			}

			const result = await response.json();
			if (result.status) {
				setShowModal(false); // Close the modal
				sessionStorage.setItem('showSnackbar', 'true');
				window.location.reload();
			} else {
				alert('Failed to upload file.');
			}
		} catch (error) {
			console.error('Error uploading file:', error);
			alert('An error occurred while uploading the file.');
		}
	};

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					midSelected: midSelected,
				};
				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getMorningTaskDetailsByMid`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});
				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}
				const mtData = await mtResponse.json();
				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
					const initialFormData = mtData.data.reduce((acc, task) => {
						return { ...acc, ...task };
					}, {});
					setFormData(initialFormData);
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, midSelected]); // Dependencies to trigger the effect



	const [fileslist, setFileslist] = useState([]);
	useEffect(() => {
		if (token && midSelected) {
			const fetchFiles = async () => {
				setLoading(true); // Set loading state
				const formData = new FormData();
				formData.append('mid', midSelected);

				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}api/getFiles`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: formData,
					});

					if (response.ok) {
						const result = await response.json();
						console.log('Full API Response:', result);

						// Check for the new `status` key
						if (result?.status === 'success') {
							setFileslist(result.data || []); // Use an empty array as fallback for `data`
						} else {
							console.error(
								'Failed to fetch files:',
								result?.message || 'Unexpected response structure'
							);
						}
					} else {
						const errorBody = await response.text();
						console.error('API Error Response:', errorBody);
					}
				} catch (error) {
					console.error('Fetch error:', error.message || error);
				} finally {
					setLoading(false); // Ensure loading state is reset
				}
			};

			fetchFiles();
		}
	}, [token, midSelected]);




	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			// Prepare the request payload in JSON format with updated mtDetails

			const requestData = { ...formData, mid: midSelected };
			const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/saveMorningTaskDetails`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`, // Ensure correct format
				},
				body: JSON.stringify(requestData), // Stringify the updated data to send
			});

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {

				sessionStorage.setItem('showSnackbar', 'true');
				window.location.reload()
			} else {
				console.error('Failed to submit data');

			}
		} catch (error) {
			console.error('Error submitting data:', error);

		}
	};

	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}

				{/* /.content-header */}
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>

										{showSnackbar && <div className="snackbar">Form Submitted Successfully!</div>}

										{/* Display all fields from mtDetails */}
										<div className='col-sm-10'>
											{mtDetails && mtDetails.length > 0 ? (
												<>
													{mtDetails.map((task, index) => (
														<React.Fragment key={`${task.id}-${index}`}>
															<div className='row'>
																<div className='col-4 col-md-4'>
																	<p className='mt-2 pp1'>
																		<b>Daily Task : </b>{new Date(task.createdDTM).toLocaleDateString('en-US', {
																			day: 'numeric',
																			month: 'short',
																			year: 'numeric',
																			hour: 'numeric',
																			minute: 'numeric',
																		})}

																	</p>

																</div>
																<div className='col-3 col-md-3'>
																	<p className='mt-2 pp1'>
																		<b>By:</b> {task.fname + ' ' + task.lname} &nbsp;
																	</p>

																</div>
																<div className='col-4 col-md-3'>
																	<p className='mt-2 pp1'>
																		<b>	Branch :</b> {task.branch_name}
																	</p>

																</div>
																{role === 'BM' && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>
																		<table>
																			<tr>
																				<td>	<button style={{ minWidth: '10em' }}
																					className='btn btn-sm btn-info '
																					onClick={() => navigate(`/bmcombogrid`)}>
																					BM Tasks
																				</button></td>

																				<td>

																					{(formData.createdDTM >= yesterdayDate && role === 'BM') && (
																						<button style={{ minWidth: '10em' }}
																							className='btn btn-sm btn-primary'
																							onClick={() => setShowModal(true)}>
																							Upload Files
																						</button>
																					)}

																				</td>
																			</tr>
																		</table>

																	</div>)}

																{role === 'CM' && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>
																		<button style={{ minWidth: '10em' }}
																			className='btn btn-sm btn-info '
																			onClick={() => navigate(`/bmccombogrid`)}>
																			BM Tasks
																		</button>
																	</div>)}


																{(role === 'ZONAL_MANAGER' || role === 'AVP') && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>
																		<button style={{ minWidth: '10em' }}
																			className='btn btn-sm btn-info '
																			onClick={() => navigate(`/Bm_z_CombinedGrid`)}>
																			BM Tasks
																		</button>
																	</div>)}


															</div>
															<form onSubmit={(e) => handleEditSubmit(e)}>

																<div className='row'>
																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2  pp1'>
																			<i class="fas fa-users bg-danger  mr-2"></i>	 Manpower
																		</p>
																	</div>

																	{/* is All Manpower Available?  */}
																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0101 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			{task.mt0100} :  </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className="input-group">
																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0101 && !task.mt0101 ? 'empty-field' : ''
																					}`}
																				name="mt0101"
																				id="mt0101"
																				value={formData.mt0101 || task.mt0101}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option> {/* Default empty option */}
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>

																	</div>
																	{formData.mt0101 === 'No' && (
																		<div className='col-12 col-md-5 mb-2'>
																			<div className='input-group'>

																				<textarea readOnly={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																					name='mt0102'
																					id='mt0102'
																					onChange={handleInputChange}
																					className="form-control form-control-sm"
																					placeholder="Enter Description"
																					value={formData.mt0102 || task.mt0102 || ''} // Set the default value here
																					rows="1" // Adjust the number of rows to fit the field
																					maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																				/>
																			</div>
																		</div>
																	)}
																</div>
																<div className='row'>
																	{/* is All Manpower in proper dress code?  */}

																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0201 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			{task.mt0200} : </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className='input-group'>

																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0201 && !task.mt0201 ? 'empty-field' : ''
																					}`}
																				name='mt0201'
																				id='mt0201'
																				value={
																					formData.mt0201 || task.mt0201 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	</div>
																	{formData.mt0201 === 'No' && (
																		<div className='col-12 col-md-5 mb-2'>
																			<div className='input-group'>
																				<textarea readOnly={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																					name='mt0202'
																					id='mt0202'
																					value={
																						formData.mt0202 || task.mt0202 || ''
																					}
																					onChange={handleInputChange}
																					className="form-control form-control-sm"
																					placeholder="Enter Description"
																					rows="1" // Adjust the number of rows to fit the field
																					maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																				/>

																			</div>
																		</div>
																	)}
																</div>

																<div key={index} className='row'>
																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2 pp1'>
																			<i class="fas fa-tools bg-danger  mr-2 "></i>		Machine Equipments	Maintenance
																		</p>
																	</div>
																	{/* Are there Maintainance Observation  */}

																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0701 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			{task.mt0700} : </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className='input-group'>

																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0701 && !task.mt0701 ? 'empty-field' : ''
																					}`}
																				name='mt0701'
																				id='mt0701'
																				value={
																					formData.mt0701 || task.mt0701 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select.</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	
																		<div className='col-12 col-md-5 mb-2'>
																		{formData.mt0701 === 'Yes' && (
																			<div className='input-group'>

																				<textarea readOnly={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																					name='mt0702'
																					id='mt0702'
																					value={
																						formData.mt0702 || task.mt0702 || ''
																					}
																					onChange={handleInputChange}
																					className="form-control form-control-sm"
																					placeholder="Enter Description"
																					rows="1" // Adjust the number of rows to fit the field
																					maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																				/>

																			</div>
																		)}
																		</div>
																	

																	{/* Are all Biomedical equiment working : */}
																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0401 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			{task.mt0400} : </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className='input-group'>

																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0401 && !task.mt0401 ? 'empty-field' : ''
																					}`}
																				name='mt0401'
																				id='mt0401'
																				value={
																					formData.mt0401 || task.mt0401 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	{formData.mt0401 === 'Yes' && (
																		<div className='col-12 col-md-5 mb-2'>
																			<textarea readOnly={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='mt0402'
																				id='mt0402'
																				value={
																					formData.mt0402 || task.mt0402 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>
																		</div>
																	)}
 

																</div>

																<div key={index} className='row'>
																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2 pp1'>
																			<i class="fas fa-tasks  bg-danger mr-2 "></i>	Operations
																		</p>
																	</div>

																	{/* Previous day pending reports : */}
																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0601 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			{task.mt0600} : </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className='input-group'>
																			<input type='number' disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0601 && !task.mt0601 ? 'empty-field' : ''
																					}`}
																				name='mt0601'
																				id='mt0601'
																				value={
																					formData.mt0601 || task.mt0601 || '0'
																				}
																				onChange={handleInputChange} />



																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea readOnly={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='mt0602'
																				id='mt0602'
																				value={
																					formData.mt0602 || task.mt0602 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* is Doc Avaialble or Oncall Doc arranged */}
																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0501 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			{task.mt0500} : </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className='input-group'>

																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0501 && !task.mt0501 ? 'empty-field' : ''
																					}`}
																				name='mt0501'
																				id='mt0501'
																				value={
																					formData.mt0501 || task.mt0501 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Available">Available</option>
																				<option value="On call arranged">On call arranged</option>
																			</select>

																		</div>
																	</div>																	
																		<div className='col-12 col-md-5 mb-2'>
																		{formData.mt0501 === 'On call arranged' && (
																			<div className='input-group'>

																				<textarea readOnly={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																					name='mt0502'
																					id='mt0502'
																					value={
																						formData.mt0502 || task.mt0502 || ''
																					}
																					onChange={handleInputChange}
																					className="form-control form-control-sm"
																					placeholder="Enter Doctor Details"
																					rows="1" // Adjust the number of rows to fit the field
																					maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																				/>

																			</div>
																			)}
																		</div>																	
																</div>
																<div key={index} className='row'>
																	<div className='col-12 col-md-12'  >
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2 pp1'>
																			<i class="fas fa-info-circle bg-danger mr-2 ml-2 "></i>		General
																		</p>
																	</div>
																	{/* is Pooja Done : */}
																	<div className='col-12 col-md-5'>
																		<p>
																			{task.mt0301 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#ebd729' }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)} 
																			{task.mt0300} : </p>
																	</div>
																	<div className='col-6 col-md-2 mb-2'>
																		<div className='input-group'>
																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.mt0301 && !task.mt0301 ? 'empty-field' : ''
																					}`}
																				name='mt0301'
																				id='mt0301'
																				value={
																					formData.mt0301 || task.mt0301 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>

																	</div>



																	<div className='col-12 col-md-12 mb-2 mt-2'>
																		<div className='input-group'>
																			<input
																				name='mid'
																				id='mid'
																				value={
																					formData.mid || task.mid || ''
																				}
																				type='hidden'

																			/>
																			{(task.createdDTM >= yesterdayDate && role === 'BM') && (
																				<button
																					style={{ float: 'right', marginTop: '10px' }}
																					type='submit'
																					className='btn btn-sm btn-primary'
																				>
																					Submit
																				</button>
																			)}
																		</div>
																	</div>

																</div>
															</form>
														</React.Fragment>

													))}
												</>
											) : (
												<div style={{ minHeight: '600px' }}>
													<FormSkeleton />
												</div>
											)}
										</div>

										<div className='col-sm-12 row mt-5'>

											<div className='col-12 col-md-12' >
												{fileslist.length > 0 && <p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2  pp1'>
													<i class="fas fa-file-upload bg-danger mr-2"></i>

													File Attathed with this Task
												</p>}

												{fileslist.length > 0 ? (
													fileslist.map((file, index) => (
														<p key={index} >
															<a style={{ color: '#000000' }} href={`${process.env.REACT_APP_API_URL}public/uploads/${file.file_name}`} target="_blank" rel="noreferrer">
																<i
																	className="fas fa-file-alt fa-1x "
																	style={{ color: '#3498db' }} // Customize icon color
																></i> {file.file_name}
															</a>
														</p>
													))
												) : (
													<p></p>
												)}

											</div>

										</div>




									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{/* Custom Modal */}
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<form className="form" onSubmit={handleFileSubmit}>
							<div className="form-group">
								<label>Upload .jpg or .pdf file</label>
								<input
									type="file"
									name="file"
									onChange={handleFileInputChange}
									required
									className="form-control  "
								/>
							</div>
							<button type="submit" className="btn btn-success btn-sm" disabled={loading}>
								Upload
							</button>
						</form>


					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default EditBmMorningTask;
