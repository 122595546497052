import React, { useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import './customStyle.css';
import logo from '../assets/images/logo.jpg';
import checklistImage from '../assets/images/checklist.jpg';
import { useAuth } from '../context/authprovider.js';
// import { process.env.REACT_APP_API_URL } from '../baseURL/baseURL.js';
import Dashboard from './Dashboard.js';
function Login() {
    const { login } = useAuth();
    const [formData, setFormData] = useState({
        emp_code: '',
        password: '',
        area: '',
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [role, setRole] = useState(null);
    const navigate = useNavigate();
    console.log('API URL:', process.env.REACT_APP_API_URL);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const fetchUserProfile = async (token) => {
        try {
            const profileResponse = await fetch(`${process.env.REACT_APP_API_URL}api/profile`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${token.trim()}` },
            });
            if (!profileResponse.ok) {
                throw new Error('Profile fetch error');
            }
            const profileResult = await profileResponse.json();
            if (profileResult.status) {
                sessionStorage.setItem(
                    'userProfile',
                    JSON.stringify(profileResult.profile),
                );
                setSuccess(true);
                navigate('/dashboard');
            } else {
                setResponseMessage(profileResult.message || 'Profile fetch error');
            }
        } catch (error) {
            setResponseMessage(error.message || 'Profile fetch error');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setResponseMessage('');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams(formData),
            });
            const result = await response.json();
            if (response.ok && result.status) {
                const token = result.token;
                const role = result.role;
                login(token, role); // This updates AuthContext and sessionStorage
                await fetchUserProfile(token);
            } else {
                setResponseMessage('Login failed. ' + result.message || result.error);
            }
        } catch (error) {
            setResponseMessage('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            {success ? (
                <Dashboard />
            ) : (   <div className='row justify-content-center align-items-center'>
                            <div className='col-md-6 d-none d-md-block' style={{ width: '100% !important', background: '#ffffff',  alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}  >
                                <img
                                    src={checklistImage}
                                    alt='Checklist'
                                    className='img-fluid'
                                    style={{ width: '900px', }}
                                />
                            </div>
                            <div className='col-md-6' style={{ width: '100% !important', background: '#eeeeee', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }} >
                                <div className='login-box'>
                                    <div className='login-logo'>
                                        <img
                                            src={logo}
                                            alt='VDC'
                                            className='mb-2'
                                            style={{ minWidth: '250px', maxWidth: '350px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                        />
                                        <br />
                                        <b>VDC Check List</b>
                                    </div>

                                    <div className='card' style={{ background: '#ffffff !important' }}>
                                        <div className='card-body login-card-body'>
                                            {loading && <div className='spinner'></div>}

                                            <form onSubmit={handleSubmit}>
                                                <div className='input-group mb-3'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='BMID'
                                                        name='emp_code'
                                                        value={formData.emp_code}
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                    <div className='input-group-append'>
                                                        <div className='input-group-text'>
                                                            <span className='fas fa-user'></span>
                                                        </div>
                                                    </div>
                                                </div> 

                                                <div className='input-group mb-3'>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        className='form-control'
                                                        name='password'
                                                        placeholder='Password'
                                                        value={formData.password}
                                                        required
                                                        onChange={handleChange}
                                                        autoComplete='current-password'
                                                    />
                                                    <div className='input-group-append'>
                                                        <div className='input-group-text'>
                                                            <span
                                                                className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                style={{ cursor: 'pointer' }}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-success btn-block btn-flat'
                                                            disabled={loading}
                                                        >
                                                            {loading ? 'Logging In...' : 'Login'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            {responseMessage && (
                                                <p className='text-danger'>{responseMessage}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                
            )}
        </>
    );
}

export default Login;