import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Sidebar from '../components/common/Sidebar';
import { useAuth } from '../context/authprovider';
import './customStyle.css';

//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles


const Morningtask = () => {
	const { role } = useAuth();
	const navigate = useNavigate();
	const { branch, setBranch } = useState();
	const token = sessionStorage.getItem('authToken');
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;

	// Initialize the formData state unconditionally
	const [formData, setFormData] = useState({
		mt0100: 'is All Manpower Available?',
		mt0101: '',
		mt0102: '',
		mt0200: 'is All Manpower in proper dress code?',
		mt0201: '',
		mt0202: '',
		mt0300: 'Generator Check',
		mt0301: '',
		mt0302: '',
		mt0400: 'is Pooja Done',
		mt0401: '',
		mt0402: '',
		mt0500: 'Are all Biomedical equiment working',
		mt0501: '',
		mt0502: '',
		mt0600: 'is Doc Avaialble or Oncall Doc arranged?',
		mt0601: '',
		mt0602: '',
		mt0700: 'Previous day pending reports',
		mt0701: '',
		mt0702: '',
		mt0800: 'Are there Maintainance Observation',
		mt0801: '',
		mt0802: '',
		mt0900: 'is front door glass cleaned well?',
		mt0901: '',
		mt0902: '',
		mt1000: 'is IT Portal updated with upto date information?',
		mt1001: '',
		mt1002: '',
		branch: '',
		emp_code: userEmp_code,
	});

	// Perform token validation after initializing the state
	if (!token) {
		//toast.error('Authorization token is missing.');
		return null; // Ensure the component doesn't render anything further if no token
	}

	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];

	const validateEditFormData = () => {
		console.log('Validating form data');
		if (!formData.mt0100 || formData.mt0100 === '') {
			console.log('Missing value for "is All Manpower Available?"');
			return false;
		}
		if (!formData.mt0101 || formData.mt0101 === '') {
			console.log('Missing value for "is All Manpower in proper dress code?"');
			return false;
		}
		// More validation checks...

		return true; // If everything is valid
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Form submitted');

		const isValid = validateEditFormData();
		console.log('Form valid:', isValid);
		if (!isValid) return;

		if (!validateEditFormData()) return;

		const formDataToSubmit = new FormData();
		Object.entries(formData).forEach(([key, value]) => {
			if (value !== undefined && value !== '') {
				formDataToSubmit.append(key, value);
			}
		});

		// console.log('Form Data to Submit:', formDataToSubmit);
		// console.log('Form submission triggered');
		// console.log('Form Data:', formData);
		try {
			console.log('Sending API Request');
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addMorningTask`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			// Log response status
			console.log('Response Status:', response.status);

			// Read the response once, either as text or json
			const responseData = await response.json();
			console.log('API Response:', responseData);

			if (!response.ok) {
				//toast.error( 	`Error: ${response.status} - ${responseData.message || responseData}`,);
				return;
			}
			setFormData({}); // Reset form data state to initial empty state
			//// toast.success('Form submitted successfully!');
		} catch (error) {
			console.error('Error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (type === 'radio') {
			setFormData((prevData) => ({
				...prevData,
				[name]: checked ? value : '',
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		//console.log('Updated Form Data:', formData);
	};

	return (
		<>
			<Header /> <Sidebar />
			 
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h4 className='m-0'>
									{role === 'BM' && <> BM Morning Task </>}
									{role === 'CM' && <> CM Morning Task </>}
									{role === 'SUPER_ADMIN' && <> Morning Task All List</>}
								</h4>
							</div>
						</div>
					</div>
				</div>
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										{/* <div className='row'>
											<div className='col-sm-3'>
												<input
													type='date'
													className='form-control form-control-sm'
													placeholder='Select Date'
													max={today}
												/>
											</div>
										</div> */}
										<form onSubmit={handleSubmit}>
											<div className='row mt-4'>
												<div className='col-sm-12 row'>
													<div className='col-sm-8'>
														<b> Morning Task of Dated : </b>
													</div>
													<div className='col-sm-4'></div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{ background: '#eeeeee', padding: '5px' }}
												>
													<div className='col-sm-4 ss'>
														<input
															value={formData.mt0100 || ''}
															onChange={handleInputChange}
															type='hidden'
															name='mt0100'
														/> 
														<span className='red'>
															{' '}
															is All Manpower Available?{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value='yes'
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0101'
																	checked={formData.mt0101 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0101y'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value='no'
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0101'
																	checked={formData.mt0101 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0101n'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<textarea
															value={formData.mt0102 || ''}
															onChange={handleInputChange}
															className='form-control'
															name='mt0102'
															rows='1'
															placeholder='Description'
														></textarea>
													</div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={
																formData.mt0200 ||
																'Is All Manpower in proper dress code?'
															}
															onChange={handleInputChange}
															type='hidden'
															name='mt0200'
															id='mt0200'
														/>
														<span className='red'>
															{' '}
															is All Manpower in proper dress code?
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value='yes'
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0201'
																	id='mt0201y'
																	checked={formData.mt0201 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0201y'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value='no'
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0201'
																	id='mt0201n'
																	checked={formData.mt0201 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0201n'
																>
																	No
																</label>
															</div>
														</div>
													</div>

													<div className='col-sm-4'>
														<textarea
															value={formData.mt0202 || ''}
															className='form-control'
															id='mt0202'
															name='mt0202'
															rows='1'
															placeholder='Description'
															onChange={handleInputChange} // Add this onChange handler
														></textarea>
													</div>
												</div>

												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														{' '}
														<input
															value={formData.mt0300 || 'Generator Check'}
															onChange={handleInputChange}
															type='hidden'
															name='mt0300'
															id='mt0300'
														/>{' '}
														<span className='red'> Generator Check </span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value='yes'
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0301'
																	id='mt0301y'
																	checked={formData.mt0301 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0301'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value='no'
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0301'
																	id='mt0301n'
																	checked={formData.mt0301 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0301'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'></div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														{' '}
														<input
															value={formData.mt0400 || 'is Pooja Done'}
															onChange={handleInputChange}
															type='hidden'
															name='mt0400'
															id='mt0400'
														/>
														<span className='red'> is Pooja Done </span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'yes'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0401'
																	id='mt0401y'
																	checked={formData.mt0401 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0401'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'no'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0401'
																	id='mt0401n'
																	checked={formData.mt0401 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0401'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'></div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={
																formData.mt0500 ||
																'Are all Biomedical equiment working'
															}
															onChange={handleInputChange}
															type='hidden'
															name='mt0500'
															id='mt0500'
														/>{' '}
														<span className='red'>
															{' '}
															Are all Biomedical equiment working{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'yes'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0501'
																	id='mt0501y'
																	checked={formData.mt0501 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0501'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'no'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0501'
																	id='mt0501n'
																	checked={formData.mt0501 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0501'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<textarea
															value={formData.mt0502 || ''}
															className='form-control'
															id='mt0502'
															name='mt0502'
															rows='1'
															placeholder='Description'
															onChange={handleInputChange}
														></textarea>
													</div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={
																formData.mt0600 ||
																'is Doc Avaialble or Oncall Doc arranged?'
															}
															onChange={handleInputChange}
															type='hidden'
															name='mt0600'
															id='mt0600'
														/>{' '}
														<span className='red'>
															{' '}
															is Doc Avaialble or Oncall Doc arranged?{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'Available'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0601'
																	id='mt0601'
																	checked={formData.mt0601 === 'Available'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0601'
																>
																	Available
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'Oncall Available'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0601'
																	id='mt0601y'
																	checked={
																		formData.mt0601 === 'Oncall Available'
																	}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0601'
																>
																	Oncall Arranged
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<textarea
															value={formData.mt0602 || ''}
															className='form-control'
															id='mt0602'
															name='mt0602'
															rows='1'
															placeholder='Description'
															onChange={handleInputChange}
														></textarea>
													</div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={formData.mt0700 || ''}
															onChange={handleInputChange}
															type='hidden'
															name='mt0700'
															id='mt0700'
														/>{' '}
														<span className='red'>
															{' '}
															Previous day pending reports{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'yes'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0701'
																	id='mt0701y'
																	checked={formData.mt0701 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0701'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'no'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0701'
																	id='mt0701n'
																	checked={formData.mt0701 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0701'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<textarea
															value={formData.mt0702 || ''}
															className='form-control'
															id='mt0702'
															name='mt0702'
															rows='1'
															placeholder='Description'
															onChange={handleInputChange}
														></textarea>
													</div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={formData.mt0800 || ''}
															onChange={handleInputChange}
															type='hidden'
															name='mt0800'
															id='mt0800'
														/>{' '}
														<span className='red'>
															{' '}
															Are there Maintainance Observation{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'yes'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0801'
																	id='mt0801y'
																	checked={formData.mt0801 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0801'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'no'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0801'
																	id='mt0801n'
																	checked={formData.mt0801 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0801'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<textarea
															value={formData.mt0802 || ''}
															className='form-control'
															id='mt0802'
															name='mt0802'
															rows='1'
															placeholder='Description'
															onChange={handleInputChange}
														></textarea>
													</div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={
																formData.mt0802 ||
																'is front door glass cleaned well?'
															}
															onChange={handleInputChange}
															type='hidden'
															name='mt0900'
															id='mt0900'
														/>{' '}
														<span className='red'>
															{' '}
															is front door glass cleaned well?{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'yes'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0901'
																	id='mt0901y'
																	checked={formData.mt0901 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0901'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'no'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt0901'
																	id='mt0901n'
																	checked={formData.mt0901 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt0901'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<textarea
															value={formData.mt0902 || ''}
															className='form-control'
															id='mt0902'
															name='mt0902'
															rows='1'
															placeholder='Description'
															onChange={handleInputChange}
														></textarea>
													</div>
												</div>
												<div
													className='col-sm-8 row mt-2'
													style={{
														background: '#eeeeee',
														padding: '5px',
													}}
												>
													<div className='col-sm-4'>
														<input
															value={
																formData.mt1000 ||
																'is IT Portal updated with upto date information?'
															}
															onChange={handleInputChange}
															type='hidden'
															name='mt1000'
															id='mt1000'
														/>
														<span className='red'>
															{' '}
															is IT Portal updated with upto date information?{' '}
														</span>
													</div>
													<div className='col-sm-4'>
														<div className='form-inline'>
															<div className='form-check ml-3'>
																<input
																	value={'yes'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt1001'
																	id='mt1001y'
																	checked={formData.mt1001 === 'yes'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt1001y'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	value={'no'}
																	onChange={handleInputChange}
																	className='form-check-input'
																	type='radio'
																	required
																	name='mt1001'
																	id='mt1001n'
																	checked={formData.mt1001 === 'no'}
																/>
																<label
																	className='form-check-label'
																	htmlFor='mt1001n'
																>
																	No
																</label>
															</div>
														</div>
													</div>
													<div className='col-sm-4'></div>
												</div>
												{/* Repeat for other form sections */}
											</div>
											<div className='col-sm-4 mt-2'>
												<input
													type='hidden'
													name='emp_code'
													value={formData.mt0802 || userEmp_code}
												/>
												<button type='submit' className='btn btn-sm btn-info'>
													Submit
												</button>
											</div>
										</form>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Morningtask;
